<script>
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   components: {},
   data() {
      return { baseURL, items: [] };
   },
   mounted() {
      this.axios.get(`${this.baseURL}api/tracking`).then(
         (res) => {
            this.items = res.data
            console.log(res)
         }
      ).catch(() => {

      })
   }
};
</script>

<template>
   <div>
      <section class="section bg-gradient-primary" id="statistik">
         <div class="bg-overlay-img myGradient"></div>
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-lg-8">
                  <h4 class="text-center text-white">DOKUMEN YANG DITERBITKAN SICANTIK CLOUD<br>TAHUN {{ new Date().getFullYear() }}<br><hr></h4>
                  <table class="table text-white">
                     <thead>
                        <tr>
                           <th scope="col" class="text-white">#</th>
                           <th scope="col" class="text-white">Nama Izin</th>
                           <th scope="col" class="text-white">Jumlah Terbit</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr v-for="(item, index) in items" :key="item.id_jenis_izin">
                           <th scope="row" class="text-white">{{ index + 1 }}</th>
                           <td class="text-white">{{ item.nama_izin }}</td>
                           <td class="text-white">{{ item.count }}</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
               <!-- end col -->
            </div>
            <!-- end row -->
         </div>
         <!-- end container -->
      </section>
      <!-- Cta end -->
   </div>
</template>

<style scoped>
.myGradient {
   background-color: #f5a700;
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23D18'/%3E%3Cstop offset='1' stop-color='%23F5A700'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%23FA3' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23FA3' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.43'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E");
   background-attachment: fixed;
   background-size: cover;
}
</style>