<script>
import { StarIcon } from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Features from "@/components/features";
import Footer from "@/components/footer";
import { VueRecaptcha } from 'vue-recaptcha';
const baseURL = process.env.VUE_APP_BASE_URL

export default {
   components: {
      StarIcon,
      Navbar,
      Features,
      Footer,
      VueRecaptcha
   },
   data() {
      return {
         baseURL,
         nomor: "",
         token: null,
         loading: false,
         items: []
      };
   },
   mounted() {
      console.log(baseURL)
   },
   watch: {
      loading(val) {
         if (val) {
            this.$swal({ text: 'Loading', allowOutsideClick: false })
            this.$swal.showLoading()
         } else {
            this.$swal.close()
         }
      },
   },
   methods: {
      async onSubmit() {

         if (this.token) {
            if (this.nomor) {
               this.loading = true
               this.items = []
               await this.axios.post(`${this.baseURL}api/tracking`, {
                  nomor: this.nomor,
                  token: this.token
               }).then(
                  (res) => {
                     this.loading = false
                     console.log(res)
                     this.items = res.data
                     this.$modal.show('my-first-modal');
                  }
               ).catch((error) => {
                  console.log(error)
                  this.loading = false
                  this.$toast.error(error.message, {
                     position: "top-center",
                     timeout: 2000
                  });
               })
               this.token = null
               this.$refs.recaptcha.reset();
            } else {
               this.$toast.error('Nomor Belum diisi', {
                  position: "top-center",
                  timeout: 2000
               });
            }


         } else {
            this.$toast.error('Captcha Belum dichecklist', {
               position: "top-center",
               timeout: 2000
            });
         }
      },
      onEnterKey(e) {
         if (e.keyCode === 13) {
            this.onSubmit()
         }
      },
      verifyMethod(val) {
         this.token = val
         // console.log(val)
      },
      expiredMethod() {
         this.token = null
         // console.log('expiredMethod')
      },
      renderMethod() {
         console.log('renderMethod')
      },
      errorMethod() {
         console.log('errorMethod')
      },
      errorMsg(msg) {
         this.$bvToast.toast('Toast body content', {
            title: msg,
            variant: 'danger',
            solid: true
         })
      }
   }
};
</script>

<template>
   <div>
      <Navbar />
      <div v-scroll-spy>
         <!-- Hero Start -->
         <section
            class="hero-8 bg-center position-relative"
            :style="{
               'background-image':
                  'url(' + require('@/assets/images/hero-8-bg.jpg') + ')',
            }"
            id="home"
         >
            <div class="bg-overlay bg-dark"></div>
            <div class="container">
               <div class="row justify-content-center hero-content">
                  <div class="col-lg-9">
                     <div class="text-center">
                        <star-icon class="text-white sw-1_5 icon-lg icon-spin mb-4"></star-icon>
                        <h1
                           class="font-weight-semibold mb-4 text-white hero-8-title"
                        >TRACKING SICANTIK</h1>
                        <p
                           class="mb-5 text-white-70 w-lg-75 mx-auto"
                        >Silahkan masukan nomor pendaftaran Anda</p>
                        <div class="text-center subscribe-form margin-t-30">
                           <form v-on:submit.prevent>
                              <input
                                 type="text"
                                 placeholder="No. Pendaftaran"
                                 class="form-control"
                                 v-model="nomor"
                                 @keyup="onEnterKey"
                              />
                              <button
                                 type="button"
                                 class="btn-primary rounded-pill btn btn-none"
                                 @click="onSubmit"
                              >Lacak</button>
                           </form>

                           <vue-recaptcha
                              ref="recaptcha"
                              @verify="verifyMethod"
                              class="mt-3 d-flex justify-content-center"
                              sitekey="6Lf_GuMeAAAAAKudvIx2RxTD94rFKTHSzsCOgX45"
                              @expired="expiredMethod"
                              @render="renderMethod"
                              @error="errorMethod"
                           ></vue-recaptcha>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <vue-particles
               class="particles-effect"
               color="#dedede"
               :moveSpeed="6"
               :particleSize="4"
            ></vue-particles>
         </section>
         <!-- Hero End -->

         <Features />
         <Footer />
      </div>

      <modal
         name="my-first-modal"
         :adaptive="true"
         :scrollable="true"
         width="45%"
         height="60%"
         transition="pop-out"
      >
         <template>
            <div class="myModal">
               <b-card title="List Proses" class="m-2 card-modal">
                  <ul class="timeline">
                     <li
                        :class="`${item.status === 'Selesai' ? '' : 'danger'}`"
                        v-for="(item) in items"
                        :key="item.id"
                     >
                        <h6>{{ item.nama_proses }}</h6>
                        <p>
                           <small>Oleh {{ item.diproses_by || '-' }}</small>
                           <br />
                           <small>{{ item.end_date }}</small>
                        </p>
                     </li>
                  </ul>
               </b-card>
            </div>
         </template>
      </modal>
   </div>
</template>


<style scoped>
.hero-8 {
   padding: 200px 0 380px !important;
}
.particles-effect {
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0px;
}
.card-modal {
   max-height: 50vh;
   overflow: auto;
}

.myModal {
   padding: 20px;
   scroll-behavior: smooth;
}

ul.timeline {
   list-style-type: none;
   position: relative;
}
ul.timeline:before {
   content: " ";
   background: #d4d9df;
   display: inline-block;
   position: absolute;
   left: 29px;
   width: 2px;
   height: 100%;
   z-index: 400;
}
ul.timeline > li {
   margin: 20px 0;
   padding-left: 20px;
}
ul.timeline > li.danger:before {
   border: 3px solid #aa002b;
}
ul.timeline > li:before {
   content: " ";
   background: white;
   display: inline-block;
   position: absolute;
   border-radius: 50%;
   border: 3px solid #00aa4d;
   left: 20px;
   width: 20px;
   height: 20px;
   z-index: 400;
}
</style>